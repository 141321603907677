import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { CircularProgress, CssBaseline } from '@material-ui/core';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './features/common/themes/ThemProvider';
import ConfigGenerator from './features/common/utils/configGenerator';
import axios from 'axios';

declare global {
  interface Window {
    themeConfig: object;
  }
}


const getThemeData = (moduleName, featureName, variableName) =>{
  return window.themeConfig
}

function constructConfigObject(configs: any) {
  ConfigGenerator.BuildConfigurations(configs)
}
const themeConfig : any= window.themeConfig
let themeCode = themeConfig.website?.themeCode
if(!themeCode){
  themeCode = "theme_defaultTheme"
}
axios.get(`./themes/${themeCode}.json`)
  .then((configRes: { data: any; }) => {
    window.themeConfig = configRes.data
  }
  ).catch((err: any) => {
    console.log(err);
  })

axios.get('./config.json')
  .then((configRes: { data: any; }) => {
    constructConfigObject(configRes.data)
    renderApp();
  }
  ).catch((err: any) => {
    console.log(err);
  })

function renderApp() {
  const AppComponent = React.lazy(() => import('./AppComponent'));
  ReactDOM.render(
    <ThemeProvider>
      <CssBaseline />
      <Suspense fallback={<div className="loaderStyles"><CircularProgress color="secondary" /></div>}>
        <AppComponent  />
      </Suspense>
    </ThemeProvider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
